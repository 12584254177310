import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Notices from "@/views/Notices.vue";
import NoticeDetails from "@/views/NoticeDetails.vue";
import NoticeCreate from "@/views/NoticeCreate.vue";
import Newsletters from "@/views/Newsletters.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/hiring-notices",
    name: "Hiring notices",
    component: Notices,
  },
  {
    path: "/leaving-notices",
    name: "Leaving notices",
    component: Notices,
  },
  {
    path: "/notices/create",
    name: "Notice create",
    component: NoticeCreate,
  },
  {
    path: "/notices/:token",
    name: "Notice details",
    component: NoticeDetails,
  },
  {
    path: "/newsletters",
    name: "Newsletters",
    component: Newsletters,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
