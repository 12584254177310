<template>
  <v-card outlined>
    <v-card-title>Distrubution</v-card-title>
    <v-card-text>
      <v-container v-if="notice">
        <v-row
          ><v-col cols="12" sm="12" class="py-0">
            <div
              v-for="distributionitem in filteredItems"
              :key="distributionitem.id"
            >
              <v-checkbox
                :label="distributionitem.name"
                v-model="notice.distribution_items"
                :value="distributionitem.id"
                style="height: 30px"
              ></v-checkbox>
              <ul class="ml-4">
                <li
                  v-for="recipient in distributionitem.recipients"
                  :key="recipient.id"
                >
                  {{ recipient.email }}
                </li>
              </ul>
            </div>
            <div v-if="notice && notice.properties.manager_email">
              <v-checkbox
                label="Supérieur hierarchique"
                style="height: 30px"
                v-model="notice.properties.notify_manager"
              ></v-checkbox>
              <ul class="ml-4">
                <li>
                  {{ notice.properties.manager_email }}
                </li>
              </ul>
            </div>
            <div class="mt-6">
              <combobox :notice="notice"></combobox>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import Combobox from "@/components/Combobox.vue";

export default {
  components: { Combobox },
  props: ["notice"],
  data: () => ({ distributionitems: [], filteredItems: [], selected: [] }),
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/distribution-items`,
    });
    this.distributionitems = data;
    this.filteredItems = data
      .filter((d) =>
        this.notice.type === "HiringNotice"
          ? d.name.indexOf("leaving_notice") === -1
          : d.name.indexOf("hiring_notice") === -1
      )
      .map((d) => {
        d.name = d.name.split(" hiring_notice")[0].split(" leaving_notice")[0];
        return d;
      });
    this.notice.distribution_items = this.notice.distribution_items.map((d) =>
      typeof d === "object" ? d.id : d
    );
  },
  watch: {
    "notice.type": function () {
      this.filteredItems = this.distributionitems
        .filter((d) =>
          this.notice.type === "HiringNotice"
            ? d.name.indexOf("leaving_notice") === -1
            : d.name.indexOf("hiring_notice") === -1
        )
        .map((d) => {
          d.name = d.name
            .split(" hiring_notice")[0]
            .split(" leaving_notice")[0];
          return d;
        });
    },
  },
};
</script>
