var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('employees-autocomplete',{ref:"employeeAutocomplete",attrs:{"type":_vm.$route.path === '/hiring-notices' ? 'E' : 'S'}}),(_vm.$route.path === '/hiring-notices')?_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","block":"","disabled":this.$refs.employeeAutocomplete &&
          !this.$refs.employeeAutocomplete.$data.selected},on:{"click":function($event){return _vm.createFromTemplate('HiringNotice')}}},[_vm._v("Créer un avis d'entrée")]):_c('v-btn',{staticClass:"mb-5",attrs:{"outlined":"","block":"","disabled":this.$refs.employeeAutocomplete &&
          !this.$refs.employeeAutocomplete.$data.selected},on:{"click":function($event){return _vm.createFromTemplate('LeavingNotice')}}},[_vm._v("Créer un avis de sortie")]),_c('v-card',{staticClass:"pa-10",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mb-6",attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.notices,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"locale":"fr-CH","search":_vm.search,"footer-props":{
            itemsPerPageOptions: [10, 30, 50],
            itemsPerPageText: 'Élements par page',
          }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var items = ref.items;
return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item.token,on:{"click":function($event){return _vm.$router.push(("/notices/" + (item.token)))}}},[_c('td',[_vm._v(_vm._s(item.last_name))]),_c('td',[_vm._v(_vm._s(item.first_name))]),_c('td',[_vm._v(" "+_vm._s(item.properties.department || item.properties.service || item.properties.center || item.properties.transversalsupport || item.properties.direction || "-")+" ")]),_c('td',[_vm._v(_vm._s(item.role))]),(item)?_c('td',[_vm._v(" "+_vm._s(_vm.$route.path === "/hiring-notices" ? item.hiring_date && _vm.format( _vm.parse(item.hiring_date, "yyyy-MM-dd", new Date()), "dd.MM.yyyy" ) : item.leaving_date && _vm.format( _vm.parse(item.leaving_date, "yyyy-MM-dd", new Date()), "dd.MM.yyyy" ))+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(_vm.format(_vm.parseISO(item.updated_at), "dd.MM.yyyy HH:mm"))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.submission_date ? _vm.format( _vm.parse( item.submission_date, "yyyy-MM-dd", new Date() ), "dd.MM.yyyy" ) : "-")+" ")])])}),0)]}},{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }