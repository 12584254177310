<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn
          outlined
          dense
          block
          @click="sendConfig(output)"
          :disabled="!selected"
          >Envoyer cette configuration au Service Communication et vie du
          Campus</v-btn
        >
      </v-col></v-row
    >
    <v-card outlined class="mt-5">
      <v-card-title>Prévisualisation du résultat</v-card-title>
      <v-card-text> <div v-html="output"></div></v-card-text>
    </v-card>

    <v-row align="center" v-if="displayCode">
      <v-col cols="12">
        <prism-editor
          v-model="output"
          language="html"
          :readonly="true"
          :highlight="highlighter"
          class="my-editor"
        ></prism-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { html as beautify } from "js-beautify";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
  props: ["joins", "leaves", "sendConfig", "selected"],
  components: {
    PrismEditor,
  },
  data: () => ({
    output: beautify(
      `<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" class="inout" width="100%"> <tr class="title"> <td colspan="2"> <h2>Entrées</h2> </td> </tr></table><br /><table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" class="inout" width="100%"> <tr class="title"> <td colspan="2"> <h2>Sorties</h2> </td> </tr></table>`
    ),
    file: undefined,
    snackbar: false,
    text: "",
    displayCode: false,
  }),
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
    handleChange() {
      this.output = beautify(`
              <table
              bgcolor="#FFFFFF"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="inout"
              width="100%"
              >
              <tr class="title">
              <td colspan="2"><h2>Entrées</h2></td>
              </tr>
              ${Object.keys(this.joins)
                .map((key) => {
                  return `<tr class="subtitle">
              <td colspan="2"><h3>${key}</h3></td>
              </tr>
              ${this.joins[key]
                .map((notice) =>
                  notice.in_newsletter
                    ? `<tr class="entry">
              <td class="who">${notice.first_name} ${notice.last_name}</td>
              <td class="job">${notice.role}</td>
              </tr>`
                    : ""
                )
                .join("")}
              `;
                })
                .join("")}
              </table>
              <br />
              <table
              bgcolor="#FFFFFF"
              border="0"
              cellpadding="0"
              cellspacing="0"
              class="inout"
              width="100%"
              >
              <tr class="title">
              <td colspan="2"><h2>Sorties</h2></td>
              </tr>
              ${Object.keys(this.leaves)
                .map((key) => {
                  return `<tr class="subtitle">
              <td colspan="2"><h3>${key}</h3></td>
              </tr>
              ${this.leaves[key]
                .map((notice) =>
                  notice.in_newsletter
                    ? `<tr class="entry">
              <td class="who">${notice.first_name} ${notice.last_name}</td>
              <td class="job">${notice.role}</td>
              </tr>`
                    : ""
                )
                .join("")}
              `;
                })
                .join("")}
              </table>
      `);
    },
  },
  watch: {
    joins: {
      deep: true,
      handler() {
        this.handleChange();
      },
    },
    leaves: {
      deep: true,
      handler() {
        this.handleChange();
      },
    },
  },
};
</script>

<style>
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}
</style>