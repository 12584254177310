<template>
  <v-card outlined>
    <v-card-title>Type d'avis</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="py-0" cols="12" sm="12">
            <v-select
              :disabled="!!notice.created_at"
              outlined
              dense
              label="Type"
              :items="types"
              v-model="notice.type"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["notice"],
  data: () => ({
    types: [
      { text: "Avis d'entrée", value: "HiringNotice" },
      { text: "Avis de sortie", value: "LeavingNotice" },
    ],
  }),
};
</script>
