<template>
  <v-container>
    <v-form :disabled="false" v-if="notice">
      <v-row>
        <v-col><notice-type-form :notice="notice" /></v-col>
      </v-row>
      <v-row>
        <v-col><personal-data-form :notice="notice" /></v-col>
      </v-row>
      <v-row>
        <v-col><internal-data-form :notice="notice" :isCopy="isCopy" /></v-col>
      </v-row>
      <v-row>
        <v-col><distribution-form :notice="notice" /></v-col>
      </v-row>
    </v-form>
    <v-row v-if="notice">
      <v-col
        ><v-btn
          outlined
          block
          @click="submitNotice"
          :loading="submitting"
          color="primary"
          >Créer l'avis</v-btn
        ></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import PersonalDataForm from "@/components/PersonalDataForm.vue";
import InternalDataForm from "@/components/InternalDataForm.vue";
import DistributionForm from "@/components/DistributionForm.vue";
import NoticeTypeForm from "@/components/NoticeTypeForm.vue";
import goTo from "vuetify/lib/services/goto";

export default {
  async mounted() {
    const existingNotice = localStorage.getItem("notice");
    if (existingNotice) {
      const notice = JSON.parse(existingNotice);
      delete notice.token;
      delete notice.id;
      delete notice.created_at;
      delete notice.updated_at;
      delete notice.submission_date;
      notice.type = "LeavingNotice";
      notice.distribution_items = [];
      notice.properties.other_emails = null;
      notice.properties.notify_manager = false;
      this.notice = notice;
      localStorage.removeItem("notice");
      goTo(0);
      this.isCopy = true;
    } else {
      if (this.$route.query.idisa) {
        try {
          const { data: notice } = await axios({
            method: "get",
            url: `${process.env.VUE_APP_API_URI}/employees/${this.$route.query.idisa}`,
          });

          if (this.$route.query.type === "LeavingNotice") {
            notice.distribution_items = [1, 2, 3, 5, 11];
          } else {
            notice.distribution_items = [1, 2, 3, 4];
          }
          notice.properties.notify_manager = true;
          this.notice = notice;
          console.log(this.notice.distribution_items);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.initNotice();
      }

      if (
        this.$route.query.type &&
        this.$route.query.type === "LeavingNotice"
      ) {
        this.notice.type = "LeavingNotice";
      }
      this.$router.replace({ query: null });
    }
  },
  data: () => ({
    notice: undefined,
    isCopy: false,
    submitting: false,
  }),
  components: {
    PersonalDataForm,
    InternalDataForm,
    DistributionForm,
    NoticeTypeForm,
  },
  methods: {
    initNotice() {
      this.notice = {
        first_name: "",
        type: "HiringNotice",
        submission_date: null,
        last_name: "",
        address: "",
        postal_code: null,
        city: "",
        phone_number: null,
        role: null,
        hiring_date: null,
        leaving_date: null,
        institute: null,
        acronym: "",
        manager: "",
        work_location: null,
        properties: {
          sagex: null,
          center: null,
          service: null,
          direction: null,
          department: null,
          other_emails: null,
          manager_email: null,
          transversalsupport: null,
        },
        is_frozen: false,
        emails: [],
        token: "",
        section: null,
        unity: null,
        email: null,
        created_at: null,
        updated_at: null,
        distribution_items: [],
      };
    },
    async submitNotice() {
      this.submitting = true;
      try {
        const { data: created } = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/notices`,
          data: this.notice,
        });
        this.$router.push(`/notices/${created.token}`);
      } catch (error) {
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
  },
};
</script>
