<template>
  <v-combobox
    v-model="model"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    label="Autres personnes à contacter (email)"
    multiple
    small-chips
    outlined
    dense
  >
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-if="item === Object(item)"
        v-bind="attrs"
        :input-value="selected"
        label
        small
      >
        <span class="pr-2">
          {{ item.text }}
        </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: ["notice"],
  data: () => ({
    activator: null,
    attach: null,
    editing: null,
    editingIndex: -1,
    items: [],
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0,
  }),

  mounted() {
    this.items = this.notice.properties.other_emails
      ? this.notice.properties.other_emails.split(",").map((v) => ({ text: v }))
      : [];
    this.model = this.notice.properties.other_emails
      ? this.notice.properties.other_emails.split(",")
      : [];
  },

  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
          };
          this.items.push(v);
          this.nonce++;
        }
        return v;
      });
      this.notice.properties.other_emails = this.model
        .map((m) => m.text)
        .join(",");
    },
  },

  methods: {
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
  },
};
</script>
