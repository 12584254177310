<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card outlined>
          <v-card-title>Configuration Newsletter</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    outlined
                    dense
                    :items="formattedNewsletters"
                    :item-text="
                      (item, i) => {
                        return `${item.text} - ${format(
                          parse(item.value, 'yyyy-MM-dd', new Date()),
                          'dd.MM.yyyy'
                        )}`;
                      }
                    "
                    label="Selectionner une Newsletter"
                    v-model="selectedNewsletter"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedNewsletter">
      <v-col>
        <v-card outlined>
          <v-card-title>Configuration Newsletter</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        :value="computedStartDate"
                        clearable
                        label="Date de début"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="startDate = null"
                        prepend-icon="mdi-calendar"
                        :disabled="true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @input="startDateMenu = false"
                      :first-day-of-week="1"
                      locale="fr-ch"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        :value="computedEndDate"
                        clearable
                        label="Date de fin"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="endDate = null"
                        prepend-icon="mdi-calendar"
                        :disabled="true"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @input="endDateMenu = false"
                      :first-day-of-week="1"
                      locale="fr-ch"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedNewsletter">
      <v-col cols="12" sm="12" md="6">
        <v-card outlined>
          <v-card-title>Avis d'entrée</v-card-title>
          <v-card-text>
            <div
              v-for="key in Object.keys(joins)"
              :key="`${key}-hn`"
              class="mt-3"
            >
              <v-list subheader two-line flat>
                <v-subheader>{{ key || "n/a" }}</v-subheader>

                <draggable
                  v-model="joins[key]"
                  :group="key"
                  @start="drag = true"
                  @end="updateIndexes(joins[key])"
                >
                  <transition-group>
                    <v-list-item v-for="notice in joins[key]" :key="notice.id">
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          v-model="notice.in_newsletter"
                          @change="
                            (val) => {
                              updateChecked(notice.id, val);
                            }
                          "
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${notice.first_name} ${notice.last_name}`
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          `${notice.role} (${format(
                            parse(notice.hiring_date, "yyyy-MM-dd", new Date()),
                            "dd.MM.yyyy"
                          )})`
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon> mdi-drag-horizontal-variant </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </transition-group>
                </draggable>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card outlined>
          <v-card-title>Avis de sortie</v-card-title>
          <v-card-text>
            <div
              v-for="key in Object.keys(leaves).reverse()"
              :key="`${key}-ln`"
              class="mt-3"
            >
              <v-list subheader two-line flat>
                <v-subheader>{{ key || "n/a" }}</v-subheader>

                <draggable
                  v-model="leaves[key]"
                  :group="key"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <transition-group>
                    <v-list-item v-for="notice in leaves[key]" :key="notice.id">
                      <v-list-item-action>
                        <v-checkbox
                          color="primary"
                          v-model="notice.in_newsletter"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          `${notice.first_name} ${notice.last_name}`
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          `${notice.role} (${format(
                            parse(
                              notice.leaving_date,
                              "yyyy-MM-dd",
                              new Date()
                            ),
                            "dd.MM.yyyy"
                          )})`
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon> mdi-drag-horizontal-variant </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </transition-group>
                </draggable>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="selectedNewsletter"
      ><html-generator
        :joins="this.joins"
        :leaves="this.leaves"
        :sendConfig="sendConfig"
        :selected="this.selectedNewsletter"
    /></v-row>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" right top>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { format, parse, parseISO } from "date-fns";
import axios from "axios";
import HtmlGenerator from "@/components/HtmlGenerator.vue";
import { groupBy, sortBy } from "lodash";
import draggable from "vuedraggable";

export default {
  sockets: {
    updated_indexes(val) {
      val.map((v) => {
        if (this.notices.hiringNotices.find((n) => n.id === v.id)) {
          this.notices.hiringNotices.find(
            (n) => n.id === v.id
          ).newsletter_index = v.newsletter_index;
        }
        if (this.notices.leavingNotices.find((n) => n.id === v.id)) {
          this.notices.leavingNotices.find(
            (n) => n.id === v.id
          ).newsletter_index = v.newsletter_index;
        }
      });
      this.handleChange();
    },
    updated_check(val) {
      if (this.notices.hiringNotices.find((n) => n.id === val.id)) {
        this.notices.hiringNotices.find((n) => n.id === val.id).in_newsletter =
          val.in_newsletter;
      }
      if (this.notices.leavingNotices.find((n) => n.id === val.id)) {
        this.notices.leavingNotices.find((n) => n.id === val.id).in_newsletter =
          val.in_newsletter;
      }
      this.handleChange();
    },
  },
  components: { HtmlGenerator, draggable },
  async mounted() {
    const { data: newsletters } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/newsletters-dates`,
    });
    this.newsletters = newsletters;
    this.formattedNewsletters = this.newsletters.map((n, i) => ({
      text: `Newsletter #${i + 272}`,
      value: n,
      disabled: i === 0,
    }));
  },
  data: () => ({
    snackbar: { color: "", visible: false, text: "" },
    startDate: undefined,
    endDate: undefined,
    startDateMenu: false,
    endDateMenu: false,
    notices: [],
    joins: [],
    leaves: [],
    parse,
    format,
    newsletters: [],
    formattedNewsletters: [],
    selectedNewsletter: "",
  }),
  computed: {
    computedStartDate() {
      return this.startDate
        ? format(parseISO(this.startDate), "dd.MM.yyyy")
        : "";
    },
    computedEndDate() {
      return this.endDate ? format(parseISO(this.endDate), "dd.MM.yyyy") : "";
    },
  },
  watch: {
    selectedNewsletter(val) {
      const nlIndex = this.newsletters.indexOf(val);
      const start = this.newsletters[nlIndex - 1];
      this.startDate = start;
      this.endDate = val;
    },
    async startDate() {
      if (this.startDate && this.endDate) {
        try {
          const { data: notices } = await axios({
            method: "get",
            url: `${process.env.VUE_APP_API_URI}/newsletters?start=${this.startDate}&end=${this.endDate}`,
          });
          this.notices = notices;
          this.handleChange();
        } catch (error) {
          console.log(error);
        }
      }
    },
    async endDate() {
      if (this.startDate && this.endDate) {
        try {
          const { data: notices } = await axios({
            method: "get",
            url: `${process.env.VUE_APP_API_URI}/newsletters?start=${this.startDate}&end=${this.endDate}`,
          });
          this.notices = notices;
          this.handleChange();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  methods: {
    async sendConfig(config) {
      try {
        await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_URI}/newsletters`,
          data: {
            config,
            selected: format(
              parse(this.selectedNewsletter, "yyyy-MM-dd", new Date()),
              "dd.MM.yyyy"
            ),
          },
        });
        this.snackbar = {
          color: "success",
          visible: true,
          text: "Configuration envoyée",
        };
      } catch (error) {
        console.log(error);
        this.snackbar = {
          color: "error",
          visible: true,
          text: "Erreur d'envoi",
        };
      }
    },
    async updateIndexes(notices) {
      const payload = notices.map((n, i) => {
        n.newsletter_index = i;
        return { id: n.id, newsletter_index: n.newsletter_index };
      });

      try {
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/newsletters`,
          data: payload,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updateChecked(id, in_newsletter) {
      try {
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/newsletters/${id}`,
          data: { in_newsletter },
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleChange() {
      this.notices.hiringNotices.map((hn) => {
        var svcOdpt = [
          hn.properties.center,
          hn.properties.department,
          hn.properties.service,
          hn.properties.direction,
          hn.properties.transversalsupport,
        ].filter(function (x) {
          return x && x !== "";
        });
        if (svcOdpt.length) {
          svcOdpt = svcOdpt[0]
            .replace("DT", "Département")
            .replace("CE", "Centre")
            .replace("SE", "Service")
            .replace("ST", "Support transversal");
        }
        hn.svcOrDpt = svcOdpt;
        return hn;
      });

      this.notices.leavingNotices.map((hn) => {
        var svcOdpt = [
          hn.properties.center,
          hn.properties.department,
          hn.properties.service,
          hn.properties.direction,
          hn.properties.transversalsupport,
        ].filter(function (x) {
          return x && x !== "";
        });
        if (svcOdpt.length) {
          svcOdpt = svcOdpt[0]
            .replace("DT", "Département")
            .replace("CE", "Centre")
            .replace("SE", "Service")
            .replace("ST", "Support transversal");
        }

        hn.svcOrDpt = svcOdpt;
        return hn;
      });

      const joins = groupBy(
        sortBy(this.notices.hiringNotices, "newsletter_index"),
        "svcOrDpt"
      );
      const leaves = groupBy(this.notices.leavingNotices, "svcOrDpt");
      this.joins = joins;
      this.leaves = leaves;
    },
  },
};
</script>
