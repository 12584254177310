import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import axios from "axios";
import router from "./router";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

Vue.config.productionTip = false;
const tokenInterceptor = () => {
  axios.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
const init = { onLoad: "login-required" };
if (!isChrome) {
  init.checkLoginIframe = false;
}
Vue.use(VueKeyCloak, {
  init,
  config: {
    realm: process.env.VUE_APP_KC_REALM,
    url: process.env.VUE_APP_KC_URL,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
  },
  onReady: async (keycloak) => {
    if (
      window.location.href.indexOf("https://hr-io.heig-vd.ch/files") === -1 &&
      !keycloak.hasResourceRole("admin", process.env.VUE_APP_KC_CLIENT_ID)
    ) {
      keycloak.logout();
    } else {
      tokenInterceptor();
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/socket-token`,
        });
        const socket = io(`${process.env.VUE_APP_API_URI}`, {
          path: "/socket.io",
          query: { token: data },
          transport: ['websocket']
        });
        Vue.use(VueSocketIOExt, socket);
      } catch (error) {
        console.log(error);
      }
      new Vue({
        vuetify,
        router,
        render: (h) => h(App),
      }).$mount("#app");
    }
  },
});
