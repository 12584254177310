<template>
  <v-card outlined>
    <v-card-title>Données personnelles</v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col class="py-0" cols="12" sm="12" md="6">
            <v-text-field
              outlined
              dense
              label="Nom"
              v-model="notice.last_name"
            ></v-text-field>
          </v-col>
          <v-col class="py-0" cols="12" sm="12" md="6">
            <v-text-field
              outlined
              dense
              label="Prénom"
              v-model="notice.first_name"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  props: ["notice"],
};
</script>
