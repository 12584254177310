<template>
  <v-container>
    <v-form
      v-if="notice"
      :disabled="
        !!notice.submission_date ||
        isBefore(parseISO(notice.created_at), endOfDay(new Date('2022-02-15')))
      "
    >
      <v-row>
        <v-col><notice-type-form :notice="notice" /></v-col>
      </v-row>
      <v-row>
        <v-col><personal-data-form :notice="notice" /></v-col>
      </v-row>
      <v-row>
        <v-col><internal-data-form :notice="notice" /></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6"
          ><distribution-form :notice="notice"
        /></v-col>
        <v-col
          cols="12"
          sm="12"
          md="6"
          v-if="
            isAfter(
              parseISO(notice.created_at),
              endOfDay(new Date('2022-02-15'))
            )
          "
          ><v-card outlined>
            <v-card-title>Actions</v-card-title>
            <v-card-text>
              <v-row v-if="notice">
                <v-col
                  ><v-btn
                    outlined
                    block
                    @click="saveNotice"
                    :loading="saving"
                    color="primary"
                    :disabled="submitting || !!notice.submission_date"
                    >Sauvegarder</v-btn
                  ></v-col
                >
              </v-row>
              <v-row v-if="notice && submissionAlert">
                <v-col>
                  <v-alert outlined>
                    <div class="text-h6">Informations importantes</div>
                    <div v-if="notice.type === 'HiringNotice'">
                      Vous être sur le point de soumettre cet avis d'entrée. Une
                      fois soumis, les notifications de distribution ainsi que
                      le mail de bienvenue seront envoyés. Par ailleurs le
                      formulaire ne sera plus éditable.
                      <v-checkbox
                        label="Envoyer le mail de bienvenue"
                        v-model="notice.properties.welcome_email"
                      ></v-checkbox>
                      <v-text-field
                        outlined
                        dense
                        v-model="notice.email"
                        v-if="notice.properties.welcome_email"
                      ></v-text-field>
                    </div>
                    <div v-else>
                      Vous être sur le point de soumettre cet avis de sortie.
                      Une fois soumis, les notifications de distribution seront
                      envoyées. Par ailleurs le formulaire ne sera plus
                      éditable.
                    </div>
                    <v-container class="mt-2">
                      <v-row>
                        <v-col
                          ><v-btn
                            outlined
                            block
                            @click="submissionAlert = false"
                            :disabled="submitting"
                            >Annuler</v-btn
                          ></v-col
                        ><v-col
                          ><v-btn
                            outlined
                            block
                            color="red"
                            @click="submitNotice"
                            :loading="submitting"
                            :disabled="
                              notice.properties.welcome_email && !notice.email
                            "
                            >Soumettre</v-btn
                          ></v-col
                        >
                      </v-row>
                    </v-container>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row v-if="notice && !submissionAlert">
                <v-col
                  ><v-btn
                    outlined
                    block
                    @click="openSubmitAlert"
                    :loading="submitting"
                    color="red"
                    :disabled="saving || !!notice.submission_date"
                    >{{
                      notice.submission_date
                        ? `Soumis le ${format(
                            parse(
                              notice.submission_date,
                              "yyyy-MM-dd",
                              new Date()
                            ),
                            "dd.MM.yyyy"
                          )}`
                        : "Soumettre"
                    }}</v-btn
                  ></v-col
                >
              </v-row>
              <v-row
                v-if="
                  notice &&
                  notice.type === 'HiringNotice' &&
                  !!notice.submission_date
                "
              >
                <v-col
                  ><v-btn
                    outlined
                    block
                    :disabled="submitting"
                    @click="createLeavingNotice"
                    >Créer un avis de sortie</v-btn
                  ></v-col
                >
              </v-row>
              <v-row v-if="notice && !!notice.submission_date">
                <v-col
                  ><v-btn
                    outlined
                    block
                    :disabled="submitting"
                    @click="downloadDocument"
                    >Télécharger l'avis</v-btn
                  ></v-col
                >
              </v-row>
            </v-card-text>
          </v-card></v-col
        >
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" right top>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import PersonalDataForm from "@/components/PersonalDataForm.vue";
import InternalDataForm from "@/components/InternalDataForm.vue";
import DistributionForm from "@/components/DistributionForm.vue";
import NoticeTypeForm from "@/components/NoticeTypeForm.vue";
import fileDownload from "js-file-download";
import { parse, format, parseISO, isAfter, isBefore, endOfDay } from "date-fns";

export default {
  data: () => ({
    notice: undefined,
    snackbar: { color: "", visible: false, text: "" },
    saving: false,
    submitting: false,
    submissionAlert: false,
    parse,
    format,
    isAfter,
    isBefore,
    parseISO,
    endOfDay,
  }),
  components: {
    PersonalDataForm,
    InternalDataForm,
    DistributionForm,
    NoticeTypeForm,
  },
  async mounted() {
    try {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/notices/${this.$route.params.token}`,
      });
      this.notice = data;
      this.notice.properties.notify_manager =
        this.notice.properties.notify_manager === "true";
    } catch (error) {
      this.snackbar = {
        text: "Erreur de chargement",
        visible: true,
        color: "error",
      };
      console.log(error);
    }
  },
  methods: {
    openSubmitAlert() {
      this.submissionAlert = true;
    },
    async saveNotice() {
      this.saving = true;
      try {
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/notices/${this.notice.token}`,
          data: this.notice,
        });
        this.snackbar = {
          text: "Modifications enregistrées",
          visible: true,
          color: "success",
        };
      } catch (error) {
        this.snackbar = {
          text: "Erreur de mise à jour",
          visible: true,
          color: "error",
        };
        console.log(error);
      } finally {
        this.saving = false;
      }
    },
    async submitNotice() {
      this.submitting = true;
      try {
        const { data: notice } = await axios({
          method: "put",
          url: `${process.env.VUE_APP_API_URI}/notices/${this.notice.token}`,
          data: this.notice,
        });
        this.notice.submission_date = notice.submission_date;
        this.submissionAlert = false;
        this.snackbar = {
          text: "Avis soumis",
          visible: true,
          color: "success",
        };
      } catch (error) {
        this.snackbar = {
          text: "Erreur de mise à jour",
          visible: true,
          color: "error",
        };
        console.log(error);
      } finally {
        this.submitting = false;
      }
    },
    async downloadDocument() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${this.notice.token}.pdf`,
        responseType: "arraybuffer",
      });
      fileDownload(
        data,
        `${this.notice.last_name}${this.notice.first_name}_${this.notice.type}.pdf`
      );
    },
    createLeavingNotice() {
      localStorage.setItem("notice", JSON.stringify(this.notice));
      this.$router.push("/notices/create");
    },
  },
};
</script>
