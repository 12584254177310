<template>
  <v-container>
    <v-row v-if="notices">
      <v-col>
        <div
          v-for="notice in notices"
          :key="notice.token"
          @click="handleClick(notice.token)"
        >
          <v-alert
            outlined
            :color="notice.type === 'LeavingNotice' ? 'red' : 'green'"
            prominent
            border="left"
          >
            <v-row align="center">
              <v-col class="grow">
                <div class="title black--text">
                  {{ notice.first_name }} {{ notice.last_name }}
                </div>
                <div class="body-2 black--text">
                  Crée le
                  {{ format(parseISO(notice.created_at), "dd.MM.yyyy") }}
                </div>
              </v-col>
              <v-col class="shrink">
                <v-chip
                  :color="`${
                    notice.type === 'LeavingNotice' ? 'red' : 'green'
                  } lighten-1 white--text`"
                  >{{
                    notice.type === "LeavingNotice" ? "Sortie" : "Entrée"
                  }}</v-chip
                >
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { parseISO, format } from "date-fns";
export default {
  name: "Dashboard",
  data: () => ({
    notices: [],
    format,
    parseISO,
  }),
  async mounted() {
    const { data: notices } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/notices`,
    });
    this.notices = notices;
  },
  methods: {
    handleClick(token) {
      this.$router.push(`/notices/${token}`);
    },
  },
};
</script>
