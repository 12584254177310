
<template>
  <v-autocomplete
    v-model="selected"
    :items="employees"
    :search-input.sync="search"
    label="Employés"
    outlined
    dense
    @input="clearSearch"
    no-data-text="Tapez le nom d'une personne..."
    class="mt-5"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Recherchez un employé pour créer un avis...
        </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-avatar
        color="indigo"
        class="text-p font-weight-light white--text justify-center"
      >
        {{ item.acronyme }}
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.text"></v-list-item-title>
        <v-list-item-subtitle v-text="item.fonction"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>


<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  props: ["type"],
  data: () => ({
    loading: false,
    employees: [],
    selected: null,
    search: null,
  }),

  methods: {
    async queryPicker(val) {
      this.loading = true;
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URI}/employees?search=${val}&type=${this.type}`,
        });
        this.employees = data.map((e) => ({
          text: `${e.prenom} ${e.nom}`,
          nom: e.nom,
          prenom: e.prenom,
          fonction: e.fonction,
          acronyme: e.acronyme,
          value: e.employe_id,
        }));
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    clearSearch() {
      this.search = null;
    },
  },

  watch: {
    search: debounce(function (val) {
      val && val !== this.selected && this.queryPicker(val);
    }, 300),
  },
};
</script>