<template>
  <v-container>
    <v-row>
      <v-col>
        <employees-autocomplete
          ref="employeeAutocomplete"
          :type="$route.path === '/hiring-notices' ? 'E' : 'S'"
        />
        <v-btn
          outlined
          block
          @click="createFromTemplate('HiringNotice')"
          v-if="$route.path === '/hiring-notices'"
          class="mb-5"
          :disabled="
            this.$refs.employeeAutocomplete &&
            !this.$refs.employeeAutocomplete.$data.selected
          "
          >Créer un avis d'entrée</v-btn
        >
        <v-btn
          outlined
          block
          v-else
          @click="createFromTemplate('LeavingNotice')"
          class="mb-5"
          :disabled="
            this.$refs.employeeAutocomplete &&
            !this.$refs.employeeAutocomplete.$data.selected
          "
          >Créer un avis de sortie</v-btn
        >
        <v-card outlined class="pa-10">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              hide-details
              outlined
              dense
              class="mb-6"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="notices"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            class="elevation-0"
            locale="fr-CH"
            :search="search"
            :footer-props="{
              itemsPerPageOptions: [10, 30, 50],
              itemsPerPageText: 'Élements par page',
            }"
          >
            <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
            <template v-slot:body="{ items }">
              <tbody class="text-left">
                <tr
                  v-for="item in items"
                  :key="item.token"
                  @click="$router.push(`/notices/${item.token}`)"
                >
                  <td>{{ item.last_name }}</td>
                  <td>{{ item.first_name }}</td>
                  <td>
                    {{
                      item.properties.department ||
                      item.properties.service ||
                      item.properties.center ||
                      item.properties.transversalsupport ||
                      item.properties.direction ||
                      "-"
                    }}
                  </td>
                  <td>{{ item.role }}</td>
                  <td v-if="item">
                    {{
                      $route.path === "/hiring-notices"
                        ? item.hiring_date &&
                          format(
                            parse(item.hiring_date, "yyyy-MM-dd", new Date()),
                            "dd.MM.yyyy"
                          )
                        : item.leaving_date &&
                          format(
                            parse(item.leaving_date, "yyyy-MM-dd", new Date()),
                            "dd.MM.yyyy"
                          )
                    }}
                  </td>
                  <td>
                    {{ format(parseISO(item.updated_at), "dd.MM.yyyy HH:mm") }}
                  </td>
                  <td>
                    {{
                      item.submission_date
                        ? format(
                            parse(
                              item.submission_date,
                              "yyyy-MM-dd",
                              new Date()
                            ),
                            "dd.MM.yyyy"
                          )
                        : "-"
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
            <!-- eslint-disable -->
            <template
              v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
              >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
            >
            <!-- eslint-enable -->
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { parseISO, format, parse } from "date-fns";
import { debounce } from "lodash";
import EmployeesAutocomplete from "@/components/EmployeesAutocomplete";

export default {
  name: "Dashboard",
  components: { EmployeesAutocomplete },
  data: () => ({
    loading: false,
    search: "",
    notices: [],
    total: 0,
    options: {},
    format,
    parse,
    parseISO,
    headers: [],
    selected: null,
  }),
  methods: {
    createFromTemplate(type) {
      this.$router.push(
        `/notices/create?type=${type}&idisa=${this.$refs.employeeAutocomplete.$data.selected}`
      );
    },
    async getDataFromApi() {
      this.updateHeaders();
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const type =
        this.$route.path === "/hiring-notices"
          ? "HiringNotice"
          : "LeavingNotice";
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/notices?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${this.search}&type=${type}`,
      });
      this.loading = false;
      let { rows, count } = data;
      return {
        rows,
        count,
      };
    },
    updateHeaders() {
      this.headers = [
        {
          text: "Nom",
          value: "last_name",
          sortable: true,
        },
        {
          text: "Prénom",
          value: "first_name",
          sortable: true,
        },
        {
          text: "Service ou département",
          value: "filiere",
          sortable: true,
        },
        {
          text: "Fonction",
          sortable: false,
        },
        {
          text:
            this.$route.path === "/hiring-notices"
              ? "Date d'entrée"
              : "Date de sortie",
          sortable: true,
        },
        {
          text: "Date de mise à jour",
          sortable: true,
        },
        {
          text: "Date de soumission",
          sortable: true,
        },
      ];
    },
  },
  watch: {
    "$route.path": async function () {
      const data = await this.getDataFromApi();
      this.notices = data.rows;
      this.total = data.count;
    },
    search: debounce(async function () {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const type =
        this.$route.path === "/hiring-notices"
          ? "HiringNotice"
          : "LeavingNotice";
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/notices?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${this.search}&type=${type}`,
      });

      this.loading = false;
      const { rows, count } = data;
      this.notices = rows;
      this.total = count;
    }, 500),
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.notices = data.rows;
        this.total = data.count;
      },
      deep: true,
    },
  },
};
</script>
